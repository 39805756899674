/* eslint-disable camelcase */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Layoutnew, FormContainer, Heading } from '../EditWellnessJourney/styles';
const FormAddVideo = React.lazy(() => import("../FormAddVideo/index") );
import { muscleGroup, fitnessLevel } from "../../../mockData";
import { addFitnessVideo } from '../../redux/actions';
import {withTranslation} from 'react-i18next';
import Waiting from '../Waiting';

const stateForAdd = {
  title: "",
  video_category_id: undefined,
  video_url: "",
  fitness_level: "none",
  muscle_group: ""
};

class AddFitnessVideo extends Component {
  constructor() {
    super();
    this.state = {
      showLoader: false,
    };
  }

  onSubmit = (e, data) => {
    const { addFitnessVideo, history, isAdminRoute } = this.props;
    e.preventDefault();
    let updateData = {...data}

    if(updateData.fitness_level === 1){
      updateData.fitness_level = "Beginner"
    }else if(updateData.fitness_level === 2){
      updateData.fitness_level = "Intermediate"
    }else if(updateData.fitness_level === 3){
      updateData.fitness_level = "Advanced"
    }else{
      updateData.fitness_level = ""
    }

    if(updateData.muscle_group === 1){
      updateData.muscle_group = "Back"
    }else if(updateData.muscle_group === 2){
      updateData.muscle_group = "Core"
    }else if(updateData.muscle_group === 3){
      updateData.muscle_group = "Hips"
    }else if(updateData.muscle_group === 4){
      updateData.muscle_group = "Legs"
    }else if(updateData.muscle_group === 5){
      updateData.muscle_group = "Shoulder"
    }else if(updateData.muscle_group === 6){
      updateData.muscle_group = "Chest"
    }else if(updateData.muscle_group === 7){
      updateData.muscle_group = "Arms"
    }else if(updateData.muscle_group === 8){
      updateData.muscle_group = "Upper Body"
    }else if(updateData.muscle_group === 9){
      updateData.muscle_group = "Lower Body"
    }else if(updateData.muscle_group === 10){
      updateData.muscle_group = "Total Body"
    }else if(updateData.muscle_group === 11){
      updateData.muscle_group = "Mindfulness"
    }else if(updateData.muscle_group === 12){
      updateData.muscle_group = "Mental Health"
    }else{
      updateData.muscle_group = ""
    }
    updateData.levels = updateData.levels && updateData.levels.level ? updateData.levels.level : null;
    updateData.question_id = updateData.question && updateData.question.id ? updateData.question.id : null;
    updateData.wellness_goal_id= updateData.wellness_goal && updateData.wellness_goal.id ? updateData.wellness_goal.id : null;
    delete updateData.wbaQuestions;
    delete updateData.wellnessGoals;
    delete updateData.wellness_goal;
    delete updateData.question;
    this.setState({
      showLoader: true
    })
    addFitnessVideo(updateData,history, isAdminRoute);
  };

  render() {
    const { videoLibraries, themes ,t, themesHeaderAll, isAdminRoute} = this.props;
    const FitnessVideo ={
      "fields": [
        {
          "name": "muscle_group",
          "type": "drop-down",
          "label": t("Wellness Focus"),
          "placeholder": t("Select Muscle Group"),
          "key": "muscle_group",
          "mandatory": true,
        },
        {
          "name": "fitness_level",
          "type": "drop-down",
          "label": t("Fitness Level"),
          "placeholder": t("Select Fitness Level"),
          "key": "fitness_level",
          "mandatory": true,
        },
        {
          "name": "video_url",
          "type": "text",
          "label": t("Video URL"),
          "placeholder": t("http"),
          "pattern":"https?://.+",
          "mandatory": true,
        },
        {
          "name": "wellness_goal",
          "type": "wellness_goal",
          "label": t("Wellness Goal"),
          "placeholder": t("Select Wellness Goal"),
          "key": "wellness_goal",
          "mandatory": false,
        },
        {
          "name": "sub_level",
          "type": "sub_level",
          "label": t("Sub Level (WBA Questions)"),
          "placeholder": t("Select Journey Category"),
          "key": "question",
          "mandatory": false,
        },
        {
          "name": "level",
          "type": "level",
          "label": t("Level"),
          "placeholder": t("Select Level"),
          "key": "levels",
          "mandatory": false,
        },
      ],
      "button": t("Create Video"),
      "mandatoryFields": ["video_url","title", "video_categories", "muscle_group"]
    }
    const FitnessVideoTitle ={
      "field": [
        {
          "name": "title",
          "type": "text",
          "label": t("Video Title"),
          "placeholder": t("Title"),
          "mandatory": true,
        }
      ]
    }
    if (this.state.showLoader && isAdminRoute) {
      return <Waiting />;
    }
    return (
      <Layoutnew>
        <Heading>{t('Create Fitness Video')}</Heading>
        <FormContainer color={"1"}>
          <FormAddVideo
            fields={FitnessVideo.fields}
            buttonText={FitnessVideo.button}
            state={stateForAdd}
            mandatoryFields={FitnessVideo.mandatoryFields}
            video_category_id={videoLibraries}
            muscle_group={muscleGroup}
            fitness_level={fitnessLevel}
            onSubmit={this.onSubmit}
            themes={themes}
            themesHeaderAll={themesHeaderAll}
            fieldsTitle={FitnessVideoTitle.field}
          />
        </FormContainer>
      </Layoutnew>
    )
  }
}

AddFitnessVideo.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  adventureCategories: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  videoLibraries: PropTypes.array,
  addFitnessVideo: PropTypes.func.isRequired,
  themes: PropTypes.array.isRequired,
  themesHeaderAll: PropTypes.array,
  t: PropTypes.func,
  isAdminRoute: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isLoading: state.education.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  addFitnessVideo: (data, history, isAdminRoute) => dispatch(addFitnessVideo(data, history, isAdminRoute))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddFitnessVideo)));
